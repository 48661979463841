<template lang='pug'>
.page-wrap
	.property
		h3 {{ip.address}}
		p {{ip.city}}, {{ip.region}}
		h4 {{sessions.length}} Sessions (Past 30 Days)
		gMap(ref='map')
		PastSession(v-for='session in sessions' :session='session' showIp=false)
</template>

<script>

import Live from '../../mixins/Live'
import gMap from '../../map/Map'
import PastSession from '../../components/PastSession'

export default {
	data(){
		return{
			ip:Object,
			sessions:Array
		}
	},
	mixins: [Live],
	components:{gMap, PastSession},
	methods:{
		async getSessionsByPropertyAndIp(){
			let pid = this.$route.params.key;
			let ipa = this.$route.params.ipa;
			await fetch('/api/v1/sessions?pid='+pid+'&ip='+ipa+'&details=true&pages=true', {credentials: 'include'})
			.then(response => response.json())
			.then(property => {
				console.log(property.sessions);
				this.ip = property.sessions[0].ip;
				this.sessions = property.sessions[0].sessions;
				this.$refs.map.init([this.ip]);
			});
		},
		onLiveConnectionsChanged()
		{
			this.getSessionsByPropertyAndIp();
		}
	},
	created(){
		this.init();
	}

}
</script>

<style lang="stylus" scoped>

.property
	width 94vw
	margin auto
	max-width 1200px
	h3
		margin-bottom 1vh
	h4
		margin 1vh 0
</style>